define("discourse/plugins/discourse-assign/discourse/connectors/above-review-filters/assigned-to-filter", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember-decorators/component"], function (_exports, _component, _object, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AssignedToFilter = dt7948.c(class AssignedToFilter extends _component.default {
    static shouldRender(args) {
      return args.additionalFilters;
    }
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    groupIDs = (() => (this.siteSettings.assign_allowed_on_groups || "").split("|").filter(Boolean))();
    allowedGroups = this.site.groups.filter(group => this.groupIDs.includes(group.id.toString())).mapBy("name");
    updateAssignedTo(selected) {
      this.set("outletArgs.additionalFilters.assigned_to", selected.firstObject);
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateAssignedTo", [_object.action]))();
  }, [(0, _component2.classNames)("assigned-to-filter")]);
  var _default = _exports.default = AssignedToFilter;
});