define("discourse/plugins/discourse-assign/discourse/connectors/category-custom-settings/assign-settings", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AssignSettings = dt7948.c(class AssignSettings extends _component.default {
    onChangeSetting(event) {
      this.set("outletArgs.category.custom_fields.enable_unassigned_filter", event.target.checked ? "true" : "false");
    }
    static #_ = (() => dt7948.n(this.prototype, "onChangeSetting", [_object.action]))();
  }, [(0, _component2.classNames)("assign-settings")]);
  var _default = _exports.default = AssignSettings;
});