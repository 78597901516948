define("discourse/plugins/discourse-assign/discourse/connectors/advanced-search-options-below/assigned-advanced-search", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember-decorators/component"], function (_exports, _component, _object, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AssignedAdvancedSearch = dt7948.c(class AssignedAdvancedSearch extends _component.default {
    static shouldRender(args, component) {
      return component.currentUser?.can_assign;
    }
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    onChangeAssigned(value) {
      this.outletArgs.onChangeSearchedTermField("assigned", "updateSearchTermForAssignedUsername", value);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onChangeAssigned", [_object.action]))();
  }, [(0, _component2.classNames)("assigned-advanced-search")]);
  var _default = _exports.default = AssignedAdvancedSearch;
});